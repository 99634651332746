















































































































































































































































































import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import Banner from './components/banner.vue'
import ProductIntroduce from './components/product-introduce.vue'
import { projects } from '@/views/smart-sports/data'
import router from '@/router'
export default defineComponent({
  name: 'SmartSportsDesk',
  components: { ProductIntroduce, Banner },
  setup() {
    const showState = reactive({
      showsj: false,
      multirole: false,
      advantage: false,
      tabActive: 'ai'
    })
    const productAdvantage = [
      {
        title: 'AI运动识别',
        icon: 'icon01',
        list: ['智能违规提醒', '实时成绩播报', '常态化自由练习']
      },
      {
        title: '物联网智能采集',
        icon: 'icon02',
        list: ['高效数据采集', '保障课堂安全', '体测项目全覆盖']
      },
      {
        title: '精准教学',
        icon: 'icon03',
        list: ['多维学情报告', '运动过程视频', '个性化运动处方']
      }
    ]

    const handleSetShow = (type: string) => {
      showState[type] = true
    }
    function go2detail(id: number) {
      router.push(`/zhty/${showState.tabActive}/detail/${id}`)
    }
    return {
      ...toRefs(showState),
      productAdvantage,
      handleSetShow,
      projects,
      go2detail
    }
  }
})
