export const projects: Record<
  'ai' | 'hw',
  {
    id: number
    url: string
    desc: string
    detail: Array<{
      label: string
      value: string[]
    }>
  }[]
> = {
  hw: [
    {
      id: 1,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/862f6344-076a-4008-a9dc-016a861c159a.png',
      desc: '智能蓝牙心率臂带',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/24b68c11-cbb4-42b7-b531-4b3f4f29a249.png'
          ]
        },
        { label: '产品名称', value: ['智能蓝牙心率臂带'] },
        { label: '产品型号', value: ['YS-B20'] },
        {
          label: '硬件参数',
          value: [
            '屏幕：OLED液晶屏  0.96英寸',
            '电池容量：200mAh',
            '充电方式：POGO PIN磁吸充电，不超过2小时充满',
            '工作时长：满电连续工作最长达20h',
            '物理按键：数量*1，支持开关机'
          ]
        },
        {
          label: '功能参数',
          value: [
            '心率：支持；检测范围：40bpm~220bpm；精度：±5bpm',
            '体温：支持；精度：±0.3℃',
            '血氧饱和度：支持；精度：±1%',
            '计步：支持',
            '跳绳计数：支持，准确率：97%'
          ]
        },
        {
          label: '通信参数',
          value: [
            '协议：BLE 5.0',
            '距离：配合蓝牙基站使用，空旷条件下传输距离≥100米'
          ]
        },
        { label: '产品认证', value: ['国推ROHS认证'] },
        {
          label: '其他参数',
          value: [
            '防护等级：IP67',
            '材质：ABS,PC',
            '工作温度：-10℃~﹢50℃',
            '存储温度：-20℃~﹢60℃'
          ]
        }
      ]
    },
    {
      id: 2,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/193130bb-0e09-4ca9-8b72-08cebc089734.png',
      desc: '智能身高体重测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/6c01657f-405e-4c64-b1e2-fc56f70a1827.png'
          ]
        },
        { label: '产品名称', value: ['智能身高体重测试仪'] },
        {
          label: '硬件参数',
          value: [
            '支持测试杆折叠、身高光栅折叠',
            '支持钢化材质液晶触摸屏；IPS材质；分辨率：1280*720；5寸'
          ]
        },
        {
          label: '功能参数',
          value: [
            '身高：测量范围：90㎝～215㎝；分度值：0.1㎝；误差：≤±0.2㎝',
            '体重：测量范围：5kg~200kg；分度值：0.1kg；误差：≤±0.1kg'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        },
        {
          label: '其他参数',
          value: ['工作环境湿度：10%~95%', '工作环境温度：-15℃~65℃']
        }
      ]
    },
    {
      id: 3,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/b44b31ed-c832-47f7-867f-58a3cab24ce8.png',
      desc: '智能肺活量测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/fd22d1c9-01a5-43ef-a70d-87fddb61bd1d.png'
          ]
        },
        { label: '产品名称', value: ['智能肺活量测试仪'] },
        {
          label: '硬件参数',
          value: [
            '支持液晶背光显示屏',
            '供电方式：5号AAA电池',
            '工作时长：满电连续工作最长达40h（根据电池质量不同会有偏差）',
            '物理按键：数量*1，支持开关机'
          ]
        },
        {
          label: '功能参数',
          value: [
            '采用进口高精密传感器',
            '支持防补气功能，补气时自动锁定数据',
            '测量范围：0~9999ml',
            '误差：±2.5%',
            '精度：1ml'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        },
        {
          label: '其他参数',
          value: [
            '吹嘴直径：12㎜',
            '工作环境温度：-15℃~65℃',
            '工作环境湿度：10%~95%'
          ]
        }
      ]
    },
    {
      id: 4,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/ef75aa83-4921-4a11-9611-f3ccc177b84b.png',
      desc: '智能立定跳远测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/933eab23-58cb-4d9a-96c8-ffe3ea56f8dc.png'
          ]
        },
        { label: '产品名称', value: ['智能立定跳远测试仪'] },
        {
          label: '硬件参数',
          value: ['支持测试杆拆卸组装', '采用优质PVC防滑、耐磨、减震测试专用垫']
        },
        {
          label: '功能参数',
          value: [
            '采用红外线非接触式传感器测量原理',
            '测量范围：64㎝~320㎝',
            '发射接收杆距离：90㎝~120㎝（根据测试垫变化）',
            '误差范围：≤±1㎝',
            '分度值：1㎝'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        },
        {
          label: '其他参数',
          value: ['工作环境温度：-15℃~65℃', '工作环境湿度：10%~95%']
        }
      ]
    },
    {
      id: 5,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/136edc0d-c033-4e46-9df7-fb2ff580ae58.png',
      desc: '智能坐位体前屈测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/275958d1-8602-49f2-ac09-f1017d30b947.png'
          ]
        },
        { label: '产品名称', value: ['智能坐位体前屈测试仪'] },
        {
          label: '硬件参数',
          value: [
            '主控显示部分和测试杆间采用顶针式连接，可灵活拆卸，配备辅助测试折叠海绵垫',
            '支持3.5寸TFT真彩屏，内置电容式按键',
            '电池容量：1100mAh锂电池',
            '充电方式：Type-C接口充电',
            '工作时长：满电连续工作最长达15h'
          ]
        },
        {
          label: '功能参数',
          value: [
            '采用双向光电开关，自动识别手推板前推和回退，手推板可自动归位',
            '测量范围-20㎝~40㎝',
            '分度值：0.1㎝',
            '误差：≤±0.1㎝'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        },
        {
          label: '其他参数',
          value: ['工作环境温度：-15℃~65℃', '工作环境湿度：10%~95%']
        }
      ]
    },
    {
      id: 6,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/afa0dd39-924c-40da-ba91-523da1846f44.png',
      desc: '智能仰卧起坐测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/6c3dd1cb-8dc5-4b2b-af9c-00e3344d16c3.png'
          ]
        },
        { label: '产品名称', value: ['智能仰卧起坐测试仪'] },
        {
          label: '硬件参数',
          value: [
            '支持低电量提醒',
            '配备专用床体坐垫，勾脚套有海绵垫，抗压减震、防滑',
            '电池容量：1100mAh锂电池',
            '充电方式：专用标准电源适配器',
            '工作时长：满电连续工作最长达8h'
          ]
        },
        {
          label: '功能参数',
          value: [
            '采用进口红外漫反射传感器，抗阳光干扰强，探测距离远',
            '测量范围：0~9999次',
            '分度值：1次',
            '误差：≤1次'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        },
        {
          label: '其他参数',
          value: ['工作环境温度：-15℃~65℃', '工作环境湿度：10%~95%']
        }
      ]
    },
    {
      id: 7,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/a4849e7d-b2aa-4403-b78b-1fd47e985797.png',
      desc: '智能引体向上测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/115edba0-7497-427c-b903-c6f59ecfdf2e.png'
          ]
        },
        { label: '产品名称', value: ['智能引体向上测试仪'] },
        {
          label: '硬件参数',
          value: [
            '支持低电量提醒',
            '配备专用探头连接杆、三脚架',
            '电池容量：1100mAh锂电池',
            '充电方式：专用标准电源适配器',
            '工作时长：满电连续工作最长达8h'
          ]
        },
        {
          label: '功能参数',
          value: [
            '采用进口红外漫反射传感器，抗阳光干扰强，探测距离远',
            '测量范围：0~9999次',
            '分度值：1次',
            '误差：≤1次'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        },
        {
          label: '其他参数',
          value: ['工作环境温度：-15℃~65℃', '工作环境湿度：10%~95%']
        }
      ]
    },
    {
      id: 8,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/6896009c-fcc6-44d1-80da-556959e392ca.png',
      desc: '智能蓝牙跳绳',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/e87e97a4-0824-4612-b317-54c104d544db.png'
          ]
        },
        { label: '产品名称', value: ['智能蓝牙跳绳'] },
        { label: '产品型号', value: ['YS-'] },
        {
          label: '硬件参数',
          value: [
            '屏幕：LED数码管显示屏，尺寸≥42mm*10mm*7.5mm',
            '电池容量：充电锂电池，容量≥160mAh',
            '充电接口：需支持Type-C接口',
            '工作时长：满电连续工作最长达20h',
            '物理按键：数量*1，支持开关机'
          ]
        },
        {
          label: '功能参数',
          value: ['支持绊脚检测成绩自动修正', '支持蜂鸣音效', '同时连接数≥60根']
        },
        {
          label: '通信参数',
          value: [
            '协议：BLE 5.0',
            '距离：配合蓝牙基站使用，空旷条件下传输距离≥100米'
          ]
        },
        { label: '产品认证', value: ['国推RoHS认证'] }
      ]
    },
    {
      id: 9,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/778b0bc3-bad1-44e7-942f-c2ef1056e839.png',
      desc: '智能短跑测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/994be382-0d14-47f0-b080-94aad2df40bb.png'
          ]
        },
        { label: '产品名称', value: ['智能短跑测试仪'] },
        {
          label: '硬件参数',
          value: [
            '支持低电量提醒',
            '支持检测探头和支架拆卸组装',
            '电池容量：1100mAh',
            '充电接口：Micro USB接口，2小时充满',
            '工作时长：满电连续使用最长达8h'
          ]
        },
        {
          label: '功能参数',
          value: [
            '采用进口红外发射接收灯和主控，抗阳光干扰强，无线距离远',
            '支持项目：50米、100米、往返跑等',
            '支持起点违规检测',
            '测量范围：0~9999.99S',
            '分度值：0.01S',
            '误差：≤±1.5%'
          ]
        },
        {
          label: '通信参数',
          value: [
            '通信参数',
            '协议：无线2.4G',
            '距离：配合2.4G基站使用，空旷区域距离≥150m'
          ]
        },
        {
          label: '其他参数',
          value: ['工作环境温度：-15℃~65℃', '工作环境湿度：10%~95%']
        }
      ]
    },
    {
      id: 10,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/26e7dcae-6dcb-4e3d-98cf-1679e3598444.png',
      desc: '智能中长跑测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/e9601db1-970c-44bb-9b6a-e5ee24072528.png'
          ]
        },
        { label: '产品名称', value: ['智能中长跑测试仪'] },
        {
          label: '硬件参数',
          value: [
            '采用穿戴式编号背心，内置无线计圈计时卡（含芯片电池）',
            '终点采用5米防水地毯，内置感应线圈，地毯为环保无公害材质，支持折叠收纳',
            '最高可支持200名学生测试'
          ]
        },
        {
          label: '功能参数',
          value: [
            '支持无线发令枪，远程发令，发令枪通信距离≥150m',
            '支持项目：800米、1000米',
            '测量范围：0~9999.99s，分度值：0.01s',
            '误差：≤1.5%'
          ]
        }
      ]
    },
    {
      id: 11,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/5426255e-a3c8-41fe-8fb0-f5088e9ae6d9.png',
      desc: '智能实心球测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/2d1d63d2-34fd-4a28-9959-c2cf3bb45db8.png'
          ]
        },
        { label: '产品名称', value: ['智能实心球测试仪'] },
        {
          label: '功能参数',
          value: [
            '采用红外线非接触传感器',
            '支持长度≥8米、宽度≥5米的有效测量感应范围',
            '测量范围：0~40m，分度值：0.01m',
            '误差：≤±1cm'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        },
        {
          label: '其他参数',
          value: ['工作环境温度：-15℃~65℃', '工作环境湿度：10%~95%']
        }
      ]
    },
    {
      id: 12,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/05f23868-b838-4348-bbef-0794e314fa11.png',
      desc: '智能排球垫球测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/d4118102-a939-4c33-a1dd-4aa5a82d3e21.png'
          ]
        },
        { label: '产品名称', value: ['智能排球垫球测试仪'] },
        {
          label: '功能参数',
          value: [
            '采用红外线非接触式传感器，测量杆之间采用无线通讯技术',
            '测量范围：0~9999次',
            '分度值：1次',
            '误差：≤1次'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        }
      ]
    },
    {
      id: 13,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/6f349a96-ed66-4726-ac5c-0c613ac4f6c2.png',
      desc: '智能足球运球测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/421d8797-baa2-4162-beab-8babf027f837.png'
          ]
        },
        { label: '产品名称', value: ['智能足球运球测试仪'] },
        {
          label: '硬件参数',
          value: [
            '支持低电量提醒',
            '支持检测探头和支架拆卸组装',
            '电池容量：1100mAh',
            '充电接口：Micro USB接口，2小时充满',
            '工作时长：满电连续使用最长达8h'
          ]
        },
        {
          label: '功能参数',
          value: [
            '采用进口红外发射接收灯和主控，抗阳光干扰强，无线距离远',
            '支持起点违规检测',
            '测量范围：0~9999.99S',
            '分度值：0.01S',
            '误差：≤±1.5%'
          ]
        },
        {
          label: '通信参数',
          value: [
            '通信参数',
            '协议：无线2.4G',
            '距离：配合2.4G基站使用，空旷区域距离≥150m'
          ]
        },
        {
          label: '其他参数',
          value: ['工作环境温度：-15℃~65℃', '工作环境湿度：10%~95%']
        }
      ]
    },
    {
      id: 14,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/fa614128-0bc9-465e-a5e1-1cf25314cea4.png',
      desc: '智能篮球运球测试仪',
      detail: [
        {
          label: '外观',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/ec2ad3d1-496d-416d-af51-3be1b096ec56.png'
          ]
        },
        { label: '产品名称', value: ['智能篮球运球测试仪'] },
        {
          label: '硬件参数',
          value: [
            '支持低电量提醒',
            '支持检测探头和支架拆卸组装',
            '电池容量：1100mAh',
            '充电接口：Micro USB接口，2小时充满',
            '工作时长：满电连续使用最长达8h'
          ]
        },
        {
          label: '功能参数',
          value: [
            '采用进口红外发射接收灯和主控，抗阳光干扰强，无线距离远',
            '支持起点违规检测',
            '测量范围：0~9999.99S',
            '分度值：0.01S',
            '误差：≤±1.5%'
          ]
        },
        {
          label: '通信参数',
          value: ['协议：无线2.4G', '距离：配合2.4G基站使用，空旷区域距离≥150m']
        },
        {
          label: '其他参数',
          value: ['工作环境温度：-15℃~65℃', '工作环境湿度：10%~95%']
        }
      ]
    }
  ],
  ai: [
    {
      id: 1,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/cfba1dbe-dbc1-48a4-aebd-f33805e460de.png',
      desc: '立定跳远',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/d197c8ef-2c4d-45dd-a0fd-90bfd2940782.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉立定跳远'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉',
            '姿态检测：人员就位，未踩线等准备姿态检测，并支持踩线起跳、单脚起跳、长时间未跳、中途离开等违规检测',
            '精度：0.1厘米',
            '误差：±1厘米',
            '姿态指标：屈膝角度、起跳角度、摆臂幅度、平均速度、腾空时间、腾空高度',
            '检测人数：单个摄像头最多支持1个学生测试，最多可同时开启3个摄像头测试'
          ]
        },
        {
          label: '功能参数',
          value: [
            '支持测试过程中可实时查看视频流，违规提醒',
            '支持测试结束后1-1.5秒反馈成绩结果',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准',
            '支持并自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议、视频回放及运动关键帧等信息',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 2,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/870bff9c-fda5-4b79-9291-5cb654b5f60b.png',
      desc: '仰卧起坐',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/017fb53c-14e4-401a-b648-a085eccf8643.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉仰卧起坐'] },
        {
          label: '功能参数',
          value: [
            'AI类型：运动视觉',
            '姿态检测：人员就位，双手抱头、双肘触膝、背部触垫、双腿弯曲等准备姿态检测，下颌为双手未抱头、双肘未触膝、背部未触垫、双腿未弯曲，中途离开等违规检测',
            '误差：±1个/分钟',
            '姿态指标：平均耗时、屈膝夹角、身体夹角',
            '检测人数：单个摄像头最多支持1个学生测试，最多可同时开启3个摄像头测试',
            '抗干扰：测试学生背后多人近距离站立走动仍可正常进行成绩检测'
          ]
        },
        {
          label: '系统参数',
          value: [
            '支持在测试过程中可实时查看视频流，违规提醒及实时有效，无效动作个数',
            '支持测试结束后1-1.5秒反馈成绩结果',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准',
            '支持自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议、视频回放及运动关键帧等信息',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 3,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/567971f2-521c-4aec-8f69-72d0e1da60ac.png',
      desc: '引体向上',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/39564bc3-2c6b-45cc-a3c5-6b2d09ed5e91.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉引体向上'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉',
            '姿态检测：人员就位，正手握杠、直臂悬垂、身体静止等准备姿态检测，下颌为过杠（拉起时）、手臂未伸直（下落时）、动作超时、中途离开等违规检测',
            '误差：±1个',
            '姿态指标：平均耗时、大小比夹角、握距肩宽比',
            '检测人数：单个摄像头最多支持1个学生测试，最多可同时开启3个摄像头测试',
            '抗干扰：测试学生背后多人近距离站立走动仍可正常进行成绩检测'
          ]
        },
        {
          label: '系统参数',
          value: [
            '支持在测试过程中可实时查看视频流，违规提醒及实时有效，无效动作个数',
            '支持测试结束后1-1.5秒反馈成绩结果，',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准',
            '支持自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议视频回放及运动关键帧等信息',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 4,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/e79d087c-9577-44b1-abd7-35da6668fd86.png',
      desc: '跳绳',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/9d7d972d-6624-4f52-8cc3-74742038db10.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉跳绳'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉',
            '姿态检测：人员就位，双手持绳等准备姿态检测，并支持中途离开等违规检测',
            '误差：±1个/分钟',
            '姿态指标：平均速度（次/秒）、大臂夹角',
            '检测人数：单个摄像头最多支持5个学生测试，最多可同时开启3个摄像头测试',
            '抗干扰：测试学生背后多人近距离站立走动仍可正常进行成绩检测'
          ]
        },
        {
          label: '系统参数',
          value: [
            '支持测试过程中实时查看视频流，违规提醒及实时个数',
            '支持测试人员中途终止测试且离开测试圈，依然可监测并播报成绩',
            '支持测试结束后1-1.5秒反馈成绩结果，',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准',
            '支持自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议、视频回放及运动关键帧等信息',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 5,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/ec9aee1d-f4bf-4b61-943b-b68d29fd79ec.png',
      desc: '短跑',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/1068f98c-2b96-4670-b051-43fb23b99ca3.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉50米/100米'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉',
            '姿态检测：人员就位，未踩线等准备姿态检测，并支持踩线，抢跑等违规检测',
            '精度：0.1秒',
            '误差：±0.1秒',
            '姿态指标：起跑前倾角度、反应时、平均速度',
            '检测人数：单个摄像头最多支持8个学生同时测试'
          ]
        },
        {
          label: '系统参数',
          value: [
            '支持在测试过程中可实时查看起终点视频流',
            '支持测试结束后1-1.5秒反馈成绩结果',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准。',
            '支持自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议视频回放及运动关键帧等信息',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 6,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/af590b85-fba7-459d-8907-a0a168b0edd7.png',
      desc: '中长跑',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/40e47e48-d1c9-4aae-b7cd-0b4539069799.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉800/1000米'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉、人脸识别',
            '姿态检测：人员就位，未踩线等准备姿态检测，并支持踩线，抢跑等违规检测',
            '精度：0.1秒',
            '误差：≤±0.1秒',
            '姿态指标：起跑前倾角度、反应时、平均速度',
            '检测人数：单组摄像头最多支持99个学生同时测试'
          ]
        },
        {
          label: '系统参数',
          value: [
            '支持测试过程中实时查看起终点视频流',
            '支持测试结束后1-1.5秒反馈成绩结果',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准',
            '支持自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议视频回放及运动关键帧等信息',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 7,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/b2945a0f-f79a-4b23-8477-023b22adcd7b.png',
      desc: '实心球',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/146f370b-0770-461f-a0a0-76f2d660e2ac.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉实心球'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉、人脸识别',
            '姿态检测：人员未踩线，双手持球、测试中对踩线、单手掷球、出手点低于头顶、长时间未投掷、中途离开等检测',
            '误差：≤±5厘米',
            '姿态指标：反弓夹角（度）、前腿弯曲角（度）、球出手角度（度）'
          ]
        },
        {
          label: '系统参数',
          value: [
            '支持测试过程中实时查看视频流、违规提醒',
            '支持测试结束后1-1.5秒反馈成绩结果',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准执行',
            '支持自动生成个人成绩报告，包含运动姿态指标、肌群图、点评与建议、运动关键帧、视频回放等信息',
            '支持语音播报功能，在学生准备、开始、违规及结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 8,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/523e54a7-e9ac-40e5-8cf5-b77c4278d25a.png',
      desc: '排球垫球',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/edce4b37-2056-4df5-bd38-3fc52a933abe.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉排球垫球'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉',
            '姿态检测：人员就位，未踩线等准备姿态检测，并支持球落地，人或球离开测试区域等违规检测',
            '精度：1个',
            '误差：±1个',
            '姿态指标：起跑前倾角度、反应时、平均速度',
            '检测人数：单个摄像头最多支持1个学生测试，最多可同时开启3个摄像头测试'
          ]
        },
        {
          label: '系统参数',
          value: [
            '系统支持在测试过程中可实时查看起视频流',
            '支持测试结束后1-1.5秒反馈成绩结果',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准',
            '支持并自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议视频回放及运动关键帧等信息',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 9,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/34da04a9-887e-4abf-8730-ae40216ae10b.png',
      desc: '足球运球',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/53604f8c-1832-4e58-8792-c31e6c26d027.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉足球运球'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉',
            '姿态检测：人员就位，未踩线等准备姿态检测，踩线、抢跑、人或球离开测试区域等违规检测',
            '精度：0.1秒',
            '误差：±0.1秒',
            '姿态指标：起跑前倾角度、反应时、平均速度',
            '检测人数：支单个摄像头最多支持1个学生测试，最多可同时开启3个摄像头测试'
          ]
        },
        {
          label: '系统参数',
          value: [
            '支持测试过程中实时查看远近景视频流',
            '支持测试结束后1-1.5秒反馈成绩结果',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准',
            '支持自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议视频回放及运动关键帧等信息',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    },
    {
      id: 10,
      url: 'https://zyb-zhike-file.cdnjtzy.com/mis/6b436a55-a6ba-43dc-b372-288164e67192.png',
      desc: '篮球运球',
      detail: [
        {
          label: '项目',
          value: [
            'https://zyb-zhike-file.cdnjtzy.com/mis/ca634d42-21a2-4467-a5fa-53d06673d81d.png'
          ]
        },
        { label: '产品名称', value: ['云思AI视觉篮球运球'] },
        {
          label: 'AI能力',
          value: [
            'AI类型：运动视觉',
            '姿态检测：人员就位，未踩线等准备姿态检测，踩线、抢跑、人或球离开测试区域等违规检测',
            '精度：0.1秒',
            '误差：±0.1秒',
            '姿态指标：起跑前倾角度、反应时、平均速度',
            '检测人数：单个摄像头最多支持1个学生测试，最多同时开启3个摄像头测试'
          ]
        },
        {
          label: '系统参数',
          value: [
            '支持自动生成个人运动处方报告，包含运动姿态指标数据、肌群图、点评与建议视频回放及运动关键帧等信息',
            '支持测试过程中实时查看远近景视频流',
            '支持测试结束后1-1.5秒反馈成绩结果',
            '支持评分及等级标准均按照国家学生体质健康测试标准或学校自定义评分标准。',
            '支持语音播报并能在学生准备，开始，违规，结束等环节准确指引学生下一步动作'
          ]
        },
        {
          label: '其他参数',
          value: ['中华人民共和国国家版局出具的软件著作权等级证书']
        }
      ]
    }
  ]
}
